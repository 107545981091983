import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import Typed from 'typed.js';

import { basics } from '../data';
import avatar from '../images/avatar.svg';

function Title() {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [basics.name],
      typeSpeed: 40,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <span ref={el} />
    </div>
  );
}

function Subtitle() {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: basics.jobTitles,
      loop: true,
      typeSpeed: 50,
      backSpeed: 50,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <span ref={el} />
    </div>
  );
}

function TypedHead() {
  return (
    <div style={{ display: 'flex' }}>
      <Typography mt={1} sx={{ color: 'tomato' }} variant="h4">
        <Title />
      </Typography>
      <Typography mt={1} variant="h4">
        &nbsp;
      </Typography>
    </div>
  );
}

function TypedSubhead() {
  return (
    <Typography
      sx={{
        color: 'tan',
        textTransform: 'uppercase',
      }}
      variant="h5"
    >
      <Subtitle />
    </Typography>
  );
}

function Home() {
  return (
    <>
      <div className="bgImage" />
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={0}
        sx={{ minHeight: '85dvh' }}
      >
        <Avatar src={avatar} sx={{ width: 120, height: 120 }} />
        <TypedHead />
        <TypedSubhead />
      </Grid>
    </>
  );
}

export default Home;
