import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { basics } from '../data';
import Hyperlink from './Hyperlink';

const locationString = `${basics.location.city}, ${basics.location.region}`;
const { email } = basics;

const centerFlex = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

function ProfilesList() {
  return (
    <Box>
      <Box mb={1} sx={centerFlex}>
        <LocationIcon />
        <Typography color="text.secondary" ml={3 / 2}>
          {locationString}
        </Typography>
      </Box>

      <Box mb={1} sx={centerFlex}>
        <MailIcon />
        <Typography color="text.secondary" ml={3 / 2}>
          {email}
        </Typography>
      </Box>

      <Box mb={1} sx={centerFlex}>
        <LinkedInIcon />
        <Hyperlink
          text={basics.profiles[0].label}
          url={basics.profiles[0].url}
        />
      </Box>

      <Box mb={1} sx={centerFlex}>
        <GitHubIcon />
        <Hyperlink
          text={basics.profiles[1].label}
          url={basics.profiles[1].url}
        />
      </Box>
    </Box>
  );
}

export default ProfilesList;
