import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ContentHead from '../components/ContentHead';
import ItemHead from '../components/ItemHead';
import ItemSubhead from '../components/ItemSubhead';
import PaperSection from '../components/PaperSection';
import ProfilesList from '../components/ProfilesList';
import { basics, education, skills, work } from '../data';

const responsiveFlexBox = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
};

function Resume() {
  return (
    <Box mt={2}>
      <PaperSection square={false} variant="elevation">
        <Box sx={responsiveFlexBox}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography fontWeight="600" variant="h3">
              {basics.name}
            </Typography>
            <Box mb={1}>
              <Typography color="text.secondary" variant="h4">
                {basics.label}
              </Typography>
            </Box>
          </Box>
          <ProfilesList />
        </Box>
      </PaperSection>

      <PaperSection square={false} variant="elevation">
        <ContentHead text="About Me" />
        <Typography color="text.secondary" variant="body1">
          {basics.summary}
        </Typography>
      </PaperSection>

      <Grid container spacing={{ sm: 0, md: 2 }}>
        <Grid item lg={3} md={5} xs={12}>
          <PaperSection square={false} variant="elevation">
            <ContentHead text="Skills" />
            <ul
              style={{
                listStyleType: 'circle',
                paddingLeft: '0.75em',
                marginTop: '0.5em',
              }}
            >
              {skills.map((skill) => (
                <li key={skill.name}>
                  <Typography color="text.secondary" mb={2 / 3} variant="body2">
                    {skill.name}
                  </Typography>
                </li>
              ))}
            </ul>
          </PaperSection>
        </Grid>
        <Grid item lg={9} md={7} xs={12}>
          <PaperSection square={false} variant="elevation">
            <ContentHead text="Work" />
            {work.map((job) => (
              <Box key={job.name} mb={2} mt={1}>
                <Box sx={responsiveFlexBox}>
                  <Box sx={{ flexGrow: 1 }}>
                    <ItemHead text={job.name} />
                    <ItemSubhead text={job.position} />
                  </Box>
                  <Box>
                    <Typography color="text.secondary">
                      {job.dateRangeStr}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </PaperSection>
          <PaperSection square={false} variant="elevation">
            <ContentHead text="Education" />
            {education.map((school) => (
              <Box key={school.institution} mb={2} mt={1}>
                <Box sx={responsiveFlexBox}>
                  <Box sx={{ flexGrow: 1 }}>
                    <ItemHead text={school.institution} />
                    <ItemSubhead text={school.studyType} />
                  </Box>
                  <Box />
                  <Typography color="text.secondary">
                    {school.dateRangeStr}
                  </Typography>
                </Box>
              </Box>
            ))}
          </PaperSection>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Resume;
