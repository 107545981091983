import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/system';
import { NavLink as NavLinkBase } from 'react-router-dom';

function Navbar() {
  const NavLink = styled((props) => (
    <NavLinkBase {...props} end={props.to === '/'} />
  ))(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    '&.active': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      border: '1px solid',
    },
  }));

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box>
          <NavLink to="/">Home</NavLink>
        </Box>
        <Box>
          <NavLink to="/resume">Resume</NavLink>
        </Box>
        <Box>
          <NavLink to="/projects">Projects</NavLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
