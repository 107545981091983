import Typography from '@mui/material/Typography';

function ItemSubhead({ text }) {
  return (
    <Typography
      color="text.secondary"
      sx={{ fontWeight: '400' }}
      variant="body1"
    >
      {text}
    </Typography>
  );
}

export default ItemSubhead;
