import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const OpenLinkIcon = styled(OpenInNewIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Anchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  textUnderlineOffset: '0.2em',
  textDecorationColor: theme.palette.text.secondary,
  '&:hover': {
    textDecorationLine: 'underline',
  },
}));

function Hyperlink({ text, url }) {
  return (
    <Anchor href={url} rel="noreferrer" target="_blank">
      <Typography color="text.secondary" ml={3 / 2} mr={1}>
        {text}
      </Typography>
      <OpenLinkIcon fontSize="small" />
    </Anchor>
  );
}

export default Hyperlink;
