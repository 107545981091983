import './App.css';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCallback } from 'react';
import Particles from 'react-particles';
import { Route, Routes } from 'react-router-dom';
import { loadFull } from 'tsparticles';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Resume from './pages/Resume';
import particlesOptions from './particles.json';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Navbar />
      <Particles
        className="particles"
        init={particlesInit}
        options={particlesOptions}
      />
      <Container maxWidth="md">
        <Routes>
          <Route element={<Home />} path="/*" />
          <Route element={<Resume />} path="/resume" />
          <Route element={<Projects />} path="/projects" />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;
