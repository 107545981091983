import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ContentHead from '../components/ContentHead';
import PaperSection from '../components/PaperSection';
import { projects } from '../data';

function ProjectCard({
  label,
  subhead,
  technologies,
  hasLiveDemo,
  demoUrl,
  sourceUrl,
}) {
  return (
    <PaperSection square={false} variant="elevation">
      <Box>
        <Typography mb={1} sx={{ fontWeight: 'bold' }} variant="h5">
          {label}
        </Typography>
        <Stack direction="row" mb={2} spacing={1}>
          {technologies.map((technology) => (
            <Chip
              key={technology}
              label={technology}
              size="small"
              variant="outlined"
            />
          ))}
        </Stack>
        <Typography color="text.secondary" sx={{ mb: 1.5 }}>
          {subhead}
        </Typography>
      </Box>
      <Box mt={5 / 3}>
        <Button
          color="secondary"
          href={sourceUrl}
          size="small"
          startIcon={<GitHubIcon />}
          variant="outlined"
        >
          Source
        </Button>
        {hasLiveDemo && (
          <Box ml={2} sx={{ display: 'inline' }}>
            <Button
              endIcon={<OpenInNewIcon />}
              href={demoUrl}
              size="small"
              variant="outlined"
            >
              Live Demo
            </Button>
          </Box>
        )}
      </Box>
    </PaperSection>
  );
}

function Projects() {
  return (
    <>
      <Box mb={2} mt={3}>
        <ContentHead text="Personal Projects" />
      </Box>

      <Grid>
        {projects.map((project) => (
          <Grid key={project.label} item md={12} sm={12} xs={12}>
            <Box>
              <ProjectCard
                demoUrl={project.demoUrl}
                hasLiveDemo={project.hasLiveDemo}
                label={project.label}
                sourceUrl={project.url}
                subhead={project.subhead}
                technologies={project.technologies}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Projects;
