import Typography from '@mui/material/Typography';

function ItemHead({ text }) {
  return (
    <Typography color="text.primary" sx={{ fontWeight: 'bold' }} variant="h6">
      {text}
    </Typography>
  );
}

export default ItemHead;
