export const basics = {
  name: 'Will Russell',
  label: 'React Developer',
  email: 'wjrx23@gmail.com',
  summary:
    "Javascript engineer who specializes in front-end technologies and developing data-driven apps. I have five years of professional experience building web-based energy management software. I've shipped a variety of customer-facing dashboards, data-visualizations, and front-end CRUD tools built around Restful api services. Most recently, my focus shifted to building for mobile platforms using React-Native and deploying iOS and Android apps together with Expo. I enjoy rapid prototyping and creating clean, responsive UI's of my own but am equally comfortable developing from .psd or Sketch mockups.",
  location: {
    city: 'Portland',
    region: 'OR',
  },
  jobTitles: ['Front-end Engineer', 'React Developer', 'Native App Developer'],
  profiles: [
    {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/in/w-russell/',
    },
    {
      label: 'Github',
      url: 'https://github.com/WillRussell',
    },
  ],
};

export const education = [
  {
    institution: 'The Iron Yard',
    studyType: 'Ruby on Rails Engineering',
    startDate: 'Sep 2014',
    endDate: 'Dec 2014',
    dateRangeStr: 'Sep 2014 - Dec 2014',
  },
  {
    institution: 'University of North Carolina at Chapel Hill',
    studyType: "Bachelor's degree, Media & Journalism",
    startDate: 'Aug 2010',
    endDate: 'May 2013',
    dateRangeStr: 'Aug 2010 - May 2013',
  },
];

export const skills = [
  { name: 'Single Page Applications' },
  { name: 'Responsive Web Design' },
  { name: 'React.js' },
  { name: 'React Native' },
  { name: 'Expo CLI' },
  { name: 'Victory Native' },
  { name: 'Node.js' },
  { name: 'HTML5, JSX' },
  { name: 'CSS, SCSS' },
  { name: 'Material UI' },
  { name: 'Bootstrap, Tailwind' },
  { name: 'Highcharts' },
  { name: 'D3.js' },
  { name: 'Angular.js 1.x' },
  { name: 'Mapbox.js' },
  { name: 'Jest, Mocha' },
  { name: 'Android Studio' },
  { name: 'Xcode, Test Flight' },
  { name: 'MacOS, Linux' },
  { name: 'AWS, Netlify' },
  { name: 'Jira, Atlassian Products' },
  { name: 'Slack' },
];

export const projects = [
  {
    label: 'Podcastify',
    hasLiveDemo: false,
    subhead:
      'A collection of async node tasks that transform a youtube video to a podcast',
    url: 'https://github.com/WillRussell/RSS-Worker',
    demoUrl: null,
    technologies: ['Node.js', 'RSS', 'AWS', 'S3', 'Youtube-DL'],
    screengrabs: [
      'https://user-images.githubusercontent.com/8751625/231025988-f19c2317-dabf-4f88-aeeb-f9ecc5ff151a.png',
    ],
  },
  {
    label: 'Kombat Explorer API',
    hasLiveDemo: false,
    subhead:
      'Node.js app that scrapes and stores event data on upcoming MMA & Boxing events',
    url: 'https://github.com/WillRussell/kombat-explorer-api',
    demoUrl: null,
    technologies: ['Node.js', 'AWS', 'S3', 'Cheerio'],
    screengrabs: [
      'https://user-images.githubusercontent.com/8751625/185823959-24d6d6f6-5436-4bfc-9174-ec60a4f7a925.png',
    ],
  },
  {
    label: 'Kombat Explorer Client',
    hasLiveDemo: true,
    subhead: 'React app that reads from kombat-explorer-api',
    url: 'https://github.com/WillRussell/kombat-explorer-client',
    demoUrl: 'https://d20l0qhspqmr91.cloudfront.net/',
    demoUrlAlt: 'http://kombat-explorer.s3-website-us-west-2.amazonaws.com/',
    technologies: ['React', 'Material-UI', 'Cloudfront'],
    screengrabs: [
      'https://user-images.githubusercontent.com/8751625/231025437-d54fd8e2-3fbc-42c6-96e1-6b273b19d3ed.png',
    ],
  },
  {
    label: 'Stocktwits Dashboard',
    hasLiveDemo: true,
    subhead:
      "React app that enables a user to enter stock symbols and see recent tweets where they're mentioned.",
    url: 'https://github.com/WillRussell/stocktwits-dash',
    demoUrl: 'https://stocktwits-dashboard.netlify.app/',
    technologies: ['React', 'Material-UI', 'Netlify'],
    screengrabs: [
      'https://user-images.githubusercontent.com/8751625/70399335-e9d5fe00-19d7-11ea-8129-4cd6720f9e2c.png',
    ],
  },
  {
    label: 'React Material Starter',
    hasLiveDemo: true,
    subhead: 'React + Material seed app',
    url: 'https://github.com/WillRussell/React-Material-Starter',
    demoUrl: 'https://dnjzjjumrpg3j.cloudfront.net/',
    technologies: ['React', 'Material-UI'],
    demoUrlAlt:
      'http://react-material-starter.s3-website-us-west-2.amazonaws.com/',
    screengrabs: [
      'https://user-images.githubusercontent.com/8751625/231031664-62894212-6993-48f0-aa48-41432fe2f5bc.png',
      // "https://user-images.githubusercontent.com/8751625/231031674-d515fc9e-f8b5-4f44-b5cd-aac41873f370.png",
    ],
  },
  {
    label: 'Angular Starter',
    hasLiveDemo: true,
    subhead: 'Angular.js seed app',
    url: 'https://github.com/WillRussell/Angular-Starter',
    demoUrl: 'https://dev9yqjmomg31.cloudfront.net/',
    technologies: ['Angular.js', 'Angular Material'],
    screengrabs: [
      // "https://user-images.githubusercontent.com/8751625/231032197-bf21cc03-8d6a-4f8b-8554-4d5ab101bbb0.png",
      'https://user-images.githubusercontent.com/8751625/231032200-b3c7119a-cb47-4aef-8be9-1349272080e3.png',
    ],
  },
];

export const work = [
  {
    name: 'LO3 Energy',
    position: 'Software Engineer',
    startDate: 'May 2018',
    endDate: 'Apr 2020',
    dateRangeStr: 'May 2018 - Apr 2020',
    url: 'https://lo3energy.com/',
    location: 'Portland, Oregon',
  },
  {
    name: 'eGauge Systems',
    position: 'Javascript Contractor',
    startDate: 'May 2017',
    endDate: 'Dec 2017',
    dateRangeStr: 'May 2017 - Dec 2017',
    url: 'https://www.egauge.net/',
    location: 'Boulder, Colorado',
  },
  {
    name: 'Sustainable Industrial Solutions (Ndustrial.io)',
    position: 'Software Engineer',
    startDate: 'Jan 2015',
    endDate: 'Mar 2017',
    dateRangeStr: 'Jan 2015 - Mar 2017',
    url: 'https://ndustrial.io/',
    location: 'Raleigh-Durham, North Carolina',
  },
];
